// If adding a new experiment, you must do 2 things:
// 1) Add its key to the Experiments enum. This key is defined by
//    the backend and should be unique to each experiment.
// 2) Add it to the ExperimentsConfig, so that the compiler
//    knows what kinds of variant groups to expect.

export enum Experiments {
  BizPaymentUpfrontTrial = 'cfb_trial_payment_upfront',
  GoalBasedInterstitials = 'goal_based_interstitials',
  InterstitialPromo = 'interstitial_promo',
  LessonVideoWalkthroughs = 'lesson_video_walkthroughs',
  OneTimePayment = 'onetime_payment',
  OpenFirstModule = 'open_first_module',
  PaidExclusiveOverlay = 'paid_exclusive_overlay',
  ProjectRedesign = 'project_facelift_2022',
}

export type TwoBucketExperiment = 'control' | 'variant';

export interface ExperimentsConfig {
  [Experiments.BizPaymentUpfrontTrial]: TwoBucketExperiment;
  [Experiments.GoalBasedInterstitials]: TwoBucketExperiment;
  [Experiments.InterstitialPromo]: TwoBucketExperiment;
  [Experiments.LessonVideoWalkthroughs]: TwoBucketExperiment;
  [Experiments.OneTimePayment]: TwoBucketExperiment;
  [Experiments.OpenFirstModule]: TwoBucketExperiment;
  [Experiments.PaidExclusiveOverlay]: TwoBucketExperiment;
  [Experiments.ProjectRedesign]: TwoBucketExperiment;
}
